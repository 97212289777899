// export function addUAClass(){
//
//   let UA = window.navigator.userAgent;
//   let ua = UA.toLocaleLowerCase();
//
//   let bodyElm = document.getElementsByTagName('body');
//
//   let MobileDetect = require('mobile-detect');
//   let md = new MobileDetect(UA);
//
//   let os;
//   let mobile;
//
//   if(md.mobile()){
//     mobile = 'isSP';
//     os = md.os().toLocaleLowerCase();
//     bodyElm.classList.add(mobile,os);
//   }else{
//     bodyElm.classList.add('isPC');
//   }
//
//   if (ua.indexOf("msie") != -1 || ua.indexOf("trident") != -1) {
//     bodyElm.classList.add('ie');
//   } else if (ua.indexOf("edg") != -1 || ua.indexOf("edge") != -1) {
//     bodyElm.classList.add('edge');
//   } else if (ua.indexOf("opr") != -1 || ua.indexOf("opera") != -1) {
//     bodyElm.classList.add('opera');
//   } else if (ua.indexOf("chrome") != -1) {
//     bodyElm.classList.add('chrome');
//   } else if (ua.indexOf("safari") != -1) {
//     bodyElm.classList.add('safari');
//   } else if (ua.indexOf("firefox") != -1) {
//     bodyElm.classList.add('firefox');
//   } else if (ua.indexOf("opr") != -1 || ua.indexOf("opera") != -1) {
//     bodyElm.classList.add('opera');
//   }
// }


export function addUAClass() {
  let UA = window.navigator.userAgent;
  let ua = UA.toLowerCase();

  let bodyElm = document.body; // 修正

  // ライブラリの読み込み方法を適切なものに変更する必要がある
  let MobileDetect = require('mobile-detect'); // Webpackなどを使用している場合
  let md = new MobileDetect(UA);

  if (md.mobile()) {
    let mobile = 'isSP';
    let os = md.os() ? md.os().toLowerCase() : ''; // 修正: nullチェック追加
    bodyElm.classList.add(mobile, os);
  } else {
    bodyElm.classList.add('isPC');
  }

  // ブラウザの判別とクラスの追加
  if (ua.includes("msie") || ua.includes("trident")) {
    bodyElm.classList.add('ie');
  } else if (ua.includes("edg")) {
    bodyElm.classList.add('edge');
  } else if (ua.includes("opr") || ua.includes("opera")) {
    bodyElm.classList.add('opera');
  } else if (ua.includes("chrome")) {
    bodyElm.classList.add('chrome');
  } else if (ua.includes("safari")) {
    bodyElm.classList.add('safari');
  } else if (ua.includes("firefox")) {
    bodyElm.classList.add('firefox');
  }
}
